import React from "react";

import LoggedInPagesTemplate from "../../templates/LoggedInPagesTemplate";
import Guide from "../../templates/Guide";
import { Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";

const allGuideData = {
  Sendlane: {
    title: "Abandoned Cart Automation Improvement",
    subtitle:
      "Stop abandoned cart discount abuse by filtering out customers who will buy your product even without the additional discount. If you do not have an abandoned cart discount to help with conversion because of your fear that they will be abused, you can now create one!",
    guideSpan: 24,
    showStepNumbers: true,
    guideSections: [
      {
        text: "Edit your abandoned cart automation.",
      },
      {
        text: "Add a conditional split after your first email.",
        imgSrc: "/webapp/loggedInPages/useCases/sendlane/abandonedCart1.png",
      },
      {
        text: "Select Tags -> Has Tag -> “PurchaseNext14Days”. Add OR condition and select “PurchasePrevious14Days”. This will capture all customers who were expected to purchase soon since predictive technology won't be 100% accurate to the exact day when someone makes a purchase.",
      },
      {
        text: "Move your original offer to “No”.",
      },
      {
        text: "Create a new email for “Yes” to send it to customers who will purchase the product even without the discount. If you provide 10% off for abandoned cart, this could be as simple as only a 5% off for your repeat customers.",
      },
    ],
  },
};

const UseCaseAbandonedCart = () => {
  const navigate = useNavigate();
  return (
    <LoggedInPagesTemplate>
      <Row>
        <Col span={24} xxl={16}>
          <Guide guideData={allGuideData.Sendlane} />
        </Col>
        <Col span={24} xxl={16} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            style={{ height: "50px", borderRadius: "4px" }}
            onClick={() => {
              navigate("/use-cases/replenishment");
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </LoggedInPagesTemplate>
  );
};

export default UseCaseAbandonedCart;
