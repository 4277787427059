import React from "react";

import LoggedInPagesTemplate from "../../templates/LoggedInPagesTemplate";
import Guide from "../../templates/Guide";
import { Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";

const allGuideData = {
  Sendlane: {
    title: "Creating Segment Using Predictive Data",
    subtitle:
      "Create segment of customers who will be highly engaged, resulting in very high open rates (even 90%+), high CTR (4%) and high AOV.",
    guideSpan: 24,
    showStepNumbers: true,
    guideSections: [
      {
        text: "Create a new segment on Sendlane",
      },
      {
        text: "Name it “Expected to Purchase in Previous and Next 28 Days” or follow your segment naming convention",
        imgSrc: "/webapp/loggedInPages/useCases/sendlane/segment1.png",
      },
      {
        text: "In Segment Characteristics, please select “Has Tag” and select “PurchaseNext28Days” and “PurchasePrevious28Days”.",
        imgSrc: "/webapp/loggedInPages/useCases/sendlane/segment2.png",
      },
      {
        text: "Once the segment processing is complete, you will be able to send marketing emails to this segment of customers who have bought from you before and are expected to purchase soon. You can shorten the window of customers expected to purchase by selecting different combinations of 28 day tags with “PurchaseNext14Days” or “PurchasePrevious14Days”.",
      },
    ],
  },
};
const UseCaseSegments = () => {
  const navigate = useNavigate();
  return (
    <LoggedInPagesTemplate>
      <Row>
        <Col span={24} xxl={16}>
          <Guide guideData={allGuideData.Sendlane} />
        </Col>
        <Col span={24} xxl={16} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            style={{ height: "50px", borderRadius: "4px" }}
            onClick={() => {
              navigate("/use-cases/abandoned-cart");
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </LoggedInPagesTemplate>
  );
};

export default UseCaseSegments;
