import React, { useEffect, useState } from "react";

import OnboardingTemplate from "../../templates/OnboardingTemplate";
import EmailSoftwareSelectionForm from "../../organisms/EmailSoftwareSelectionForm";
import Guide from "../../templates/Guide";
import FormField from "../../molecules/FormField";
import OverflowContainer from "../../atoms/OverflowContainer";
import { Form, Row } from "antd";
import Button from "../../atoms/Button";
import { useAuth } from "../../../provider/authProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../../../config/config.js";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const allGuideData = {
  Sendlane: {
    title: "Generate Access Token:",
    showStepNumbers: true,
    guideSections: [
      {
        text: "Go to Settings -> API on your Sendlane account",
        imgSrc: "/webapp/loggedInPages/onboarding/step2/guide1.png",
      },
      {
        text: "Click on + API Token",
      },
      {
        text: "Type Beena as the token name and click create",
      },
      {
        text: "You will see the following message telling you the access token has been created. Click on Copy API Token.",
        imgSrc: "/webapp/loggedInPages/onboarding/step2/guide2.png",
      },
      {
        text: "Paste that down here:",
      },
    ],
  },
};
const Step2 = () => {
  const query = useQuery();
  const { backendAxios, token } = useAuth();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.userInfo);
  const [selectedEmailMarketingSoftware, setSelectedEmailMarketingSoftware] =
    useState(null);

  const [guideData, setGuideData] = useState(null);
  const handleTokenSubmission = (values) => {
    if (user.email === config.testEmail) {
      navigate("/onboarding/step3");
    } else {
      backendAxios
        .post("apikeys", {
          apiKey: values.accessToken || null,
          apiKeyType: selectedEmailMarketingSoftware["name"],
          shop: query.get("shop"),
        })
        .then((res) => {
          console.log(res);
          window.location.href = "/onboarding/step3";
        });
    }
  };
  useEffect(() => {
    if (selectedEmailMarketingSoftware) {
      setGuideData(allGuideData[selectedEmailMarketingSoftware["name"]]);
    }
  }, [selectedEmailMarketingSoftware]);

  useEffect(() => {
    if (
      token &&
      backendAxios.defaults.headers.common["Authorization"] &&
      query.get("update") === "true" &&
      query.get("storeId")
    ) {
      backendAxios.put(`store/${query.get("storeId")}`, {});
      query.delete("update");
      query.delete("storeId");
      const newUrl = new URL(window.location.href);
      newUrl.searchParams.delete("update");
      newUrl.searchParams.delete("storeId");
      window.history.replaceState(null, "", newUrl.toString());
    } // eslint-disable-next-line
  }, [query]);

  return (
    <OnboardingTemplate
      leftCol={
        <EmailSoftwareSelectionForm
          setSelectedEmailMarketingSoftware={setSelectedEmailMarketingSoftware}
          allGuideData={allGuideData}
        />
      }
      rightCol={
        <OverflowContainer
          affixChildren={
            guideData && (
              <Form
                style={{ display: "flex", flexDirection: "column" }}
                onFinish={handleTokenSubmission}
              >
                <FormField
                  name="accessToken"
                  placeholder="Paste your access token here"
                  style={{ zIndex: 1000 }}
                  itemProps={{
                    style: {
                      width: "100%",
                      marginBottom: "0px",
                    },
                  }}
                />
                <Row>
                  <Button
                    size="large"
                    type="secondary"
                    onClick={() => navigate("/onboarding/step3")}
                    style={{
                      borderRadius: "4px",
                      border: "1px solid #000",
                      color: "#000",
                    }}
                  >
                    Skip for now
                  </Button>
                  <Button
                    size="large"
                    type="primary"
                    htmlType="submit"
                    style={{
                      borderRadius: "4px",
                      marginLeft: "10px",
                    }}
                  >
                    Submit
                  </Button>
                </Row>
              </Form>
            )
          }
        >
          <Guide guideData={guideData} />
        </OverflowContainer>
      }
    />
  );
};

export default Step2;
