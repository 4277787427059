import React from "react";

import LoggedInPagesTemplate from "../../templates/LoggedInPagesTemplate";
import Guide from "../../templates/Guide";
import { Row, Col, Button } from "antd";
import { useNavigate } from "react-router-dom";
import Title from "antd/es/typography/Title";

const allGuideData = {
  Sendlane: {
    title: "Churn Prevention/Winback",
    subtitle:
      "Send proactive churn prevention emails. This date is specific to each customer.",
    guideSpan: 24,
    showStepNumbers: true,
    guideSections: [
      {
        text: "Create a new automation.",
      },
      {
        text: "Add a trigger -> Tag Added -> Once Per Day -> ChurnNext14Days.",
        imgSrc: "/webapp/loggedInPages/useCases/sendlane/churn1.png",
      },
      {
        text: "You are all set! This automation will keep sending churn prevention emails to your customers once they are 14 days away from their predicted churn date. You can create a sequence of emails to woo them back before they churn. You can also create a segment using churn tags and send marketing emails as well.",
        imgSrc: "/webapp/loggedInPages/useCases/sendlane/churn2.png",
      },
    ],
  },
};
const UseCaseChurn = () => {
  const navigate = useNavigate();
  return (
    <LoggedInPagesTemplate>
      <Row>
        <Col span={24} xxl={16}>
          <Guide guideData={allGuideData.Sendlane} />
        </Col>
        <Col span={24} xxl={16} style={{ textAlign: "right" }}>
          <Button
            type="primary"
            style={{ height: "50px", borderRadius: "4px" }}
            onClick={() => {
              navigate("/dashboard");
            }}
          >
            Next
          </Button>
        </Col>
      </Row>
    </LoggedInPagesTemplate>
  );
};

export default UseCaseChurn;
